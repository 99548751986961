const followUpAdminData =[
    {id: '001',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24' },
    {id: '002',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24' },
    {id: '003',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24' },
    {id: '004',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24' },
    {id: '005',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24'},
    {id: '003',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24' },
    {id: '004',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24' },
    {id: '005',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24' },
    {id: '003',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24'},
    {id: '004',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24' },
    {id: '005',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24'},
    {id: '006',  name: 'Jonas jonsen Glen',role: 'kunde',description: 'Det var en gang tre bukkenbruse som skulle til sæters for å spise masse god mat og diverse', date: '22-02-24' }
    
];
export default followUpAdminData;