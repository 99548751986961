const financialData=[
    { month: 'April', totalSales: '60 346 kr', soldItems: '854', profit: '-20 000 kr',},
    { month: 'Mai', totalSales: '60 346 kr', soldItems: '854', profit:   '  2 000 kr',},
    { month: 'Juni', totalSales: '60 346 kr', soldItems: '854', profit: '20 001 kr',},
    { month: 'August', totalSales: '60 346 kr', soldItems: '854', profit: '20 000 kr',},
    { month: 'August', totalSales: '60 346 kr', soldItems: '854', profit: '20 000 kr',},
    { month: 'August', totalSales: '60 346 kr', soldItems: '854', profit: '20 000 kr',},
    { month: 'September', totalSales: '60 346 kr', soldItems: '854', profit: '20 000 kr',}
];

export default financialData;               