const transporterData=[
    {isSelected: false, id: '001 123 431 312',name:'Tine' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder'  },
    {isSelected: false, id: '002 123 431 312',name:'Ole Halvor' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder'  },
    {isSelected: false, id: '003 123 431 312',name:'Jacob Heimdal' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder'  },
    {isSelected: false, id: '004 123 431 312',name:'Jonas Josefsen' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder'  },
    {isSelected: false, id: '005 123 431 312',name:'Tine' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder' },
    {isSelected: false, id: '003 123 431 312',name:'Tine' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder' },
    {isSelected: false, id: '004 123 431 312',name:'Tine' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder'},
    {isSelected: false, id: '005 123 431 312',name:'Tine' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder' },
    {isSelected: false, id: '003 123 431 312',name:'Tine' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder' },
    {isSelected: false, id: '004 123 431 312',name:'Tine' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder' },
    {isSelected: false, id: '005 123 431 312',name:'Tine' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder' },
    {isSelected: false, id: '006 123 431 312',name:'Tine' , email: 'dani@ifisk.com', phone: '987 65 432' ,area:'Agder' }
];

export default transporterData;