import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ProductCard from './ProductCard';
import { PRODUCT_BASE_URL, API_BASE_URL } from '../../credentials';

function ProductOverviewPage() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
      // Fetch all products by default
      fetchProducts();
    }, []);

    const fetchProducts = () => {
      const url = `${API_BASE_URL}${PRODUCT_BASE_URL}`;

      fetch(url)
        .then(response => response.json())
        .then(data => {
            setProducts(data);
        })
        .catch(error => {
            console.error('Error fetching products:', error);
        });
    };

    const handleEdit = (productId) => {
        console.log("Edit product", productId); 
      };
  
      const handleDelete = (productId) => {
        console.log("Delete product", productId);
        
      };


      return (
        <div className="flex-grow p-5">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {products.map((product) => (
                    <div key={product.id} className="border p-4 flex flex-col bg-white">
                        <img src={product.imageUrl} alt={product.name} className="w-full h-48 object-cover mb-2" />
                        <h3 className="text-lg font-semibold my-2">Navn = {product.name}</h3>
                        <p className="text-gray-600 mb-1">Pris = {`${product.price} kr`} Prisen inkludert MVA</p>
                        <p className="text-sm text-gray-500 mb-2">Beskrivelse = {product.description}</p>
                        <div className="flex justify-between">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => handleEdit(product.id)}>
                                Rediger
                            </button>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => handleDelete(product.id)}>
                                Slett
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProductOverviewPage;
