
export const API_BASE_URL = 'https://lokalprodusert.com/api';
export const AUTH_SIGNUP_URL = `/Auth/signup`;
export const AUTH_LOGIN_URL = `/Auth/login`;
export const AUTH_REQUEST_PASSWORD_RESET_URL = `/Auth/requestPasswordReset`;
export const AUTH_RESET_PASSWORD_URL = `/Auth/resetPassword`;
export const AUTH_CURRENT_USER_URL = `/Auth/currentUser`;
export const AUTH_UPDATE_USER_URL = `/Auth/updateUser`;
export const AUTH_UPDATE_PASSWORD_URL = `/Auth/updatePassword`;
export const PRODUCT_BASE_URL = `/Product`;
export const PRODUCT_CITY_URL = `/Product/cities`;
export const PRODUCT_DETAIL_URL = `/Product/{id}`;
export const PRODUCT_QUERY_BY_MANUFACTURER_URL = `/Product?manufacturerEmail={manufacturerEmail}`;
export const PAYMENT_CREATE_CHECKOUT_SESSION_URL = `/Payment/create-checkout-session`;
export const PAYMENT_FINALIZE_PAYMENT_URL = `/Payment/finalize-payment`;
export const PAYMENT_ORDER_HISTORY_URL = `/Payment/order-history/{userId}`;

