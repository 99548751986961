const systemErrorData=[
    { id: '001',description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    {id: '002', description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    {id: '003', description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    {id: '003', description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    {id: '004', description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    { id: '001',description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    {id: '002', description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    {id: '004', description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    {id: '005', description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    { id: '001',description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    {id: '002', description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    {id: '003', description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
    {id: '004', description:'hello kritsisk systemfeil har skjedd siden var nede i 5 minutter ', date:'22.03.2024' },
];

export default systemErrorData;