const searchAdminData=[
    {id: '001',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456' },
    {id: '002',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456' },
    {id: '003',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456' },
    {id: '004',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456' },
    {id: '005',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456'},
    {id: '003',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456' },
    {id: '004',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456' },
    {id: '005',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456' },
    {id: '003',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456'},
    {id: '004',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456' },
    {id: '005',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456'},
    {id: '006',  role: 'Kunde',mail: 'stebbest@ggmail.com',phone: '411 12 456' }
];

export default searchAdminData;